import Vue from 'vue'

Vue.mixin({
  methods: {
    maskEmail(email) {
      const maskedEmail = email.replace(/([^@.])/g, '*').split('')
      let previous = ''

      for (let i = 0; i < maskedEmail.length; i++) {
        if (i <= 1 || previous === '.' || previous === '@') {
          maskedEmail[i] = email[i]
        }

        previous = email[i]
      }

      return maskedEmail.join('')
    },

    maskPhone(phone) {
      const maskedPhone = phone.replace(/([^().])/g, '*').split('')

      for (let i = 0; i < maskedPhone.length; i++) {
        if (i < 4) {
          maskedPhone[i] = phone[i]
        }

        if (i === maskedPhone.length - 1 || i === maskedPhone.length - 2) {
          maskedPhone[i] = phone[i]
        }
      }

      maskedPhone.splice(0, 0, '(')
      maskedPhone.splice(3, 0, ') ')

      if (maskedPhone.length === 10) {
        maskedPhone.splice(9, 0, '-')
      } else {
        maskedPhone.splice(8, 0, '-')
      }

      return maskedPhone.join('')
    },

    maskCPFWithAsterisk(cpf) {
      const userCpf = cpf.split('')
      return `${userCpf[0]}${userCpf[1]}${userCpf[2]}.${userCpf[3]}${userCpf[4]}*.**${userCpf[8]}-${userCpf[9]}${userCpf[10]}`
    },

    maskCpf(cpf) {
      const cpfMath = /(\d{3})(\d{3})(\d{3})(\d{2})/

      const c = cpf.replace(/\D/g, '').match(cpfMath)
      return `${c[1]}.${c[2]}.${c[3]}-${c[4]}`
    },

    maskCnpj(cnpj) {
      const accountCnpj = cnpj.replace(/\D/g, '').split('')
      return `${accountCnpj[0]}${accountCnpj[1]}.${accountCnpj[2]}${accountCnpj[3]}${accountCnpj[4]}.${accountCnpj[5]}${accountCnpj[6]}${accountCnpj[7]}/${accountCnpj[8]}${accountCnpj[9]}${accountCnpj[10]}${accountCnpj[11]}-${accountCnpj[12]}${accountCnpj[13]}`
    },

    maskedPhone(phone) {
      const phoneMath = phone.length > 10 ? /(\d{0,2})(\d{0,5})(\d{0,4})/ : /(\d{0,2})(\d{0,4})(\d{0,4})/

      const x = phone.replace(/\D/g, '').match(phoneMath)
      return `${x[1]} ${x[2]}-${x[3]}`
    }
  }
})
