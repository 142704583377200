import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './Utils/filter'
import Raphael from 'raphael/raphael'
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import { vMaska } from 'maska'
import VueSignature from 'vue-signature-pad'

Vue.directive('maska', vMaska)

Vue.component('VSelect', vSelect)
Vue.component('VueMultiselect', Multiselect)

Vue.use(VueSignature)

global.Raphael = Raphael
Vue.config.productionTip = false

export const EventBus = new Vue()

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
