<template>
  <router-view/>
</template>

<script>
import {xray} from './config/pluginInit'

export default {
  name: 'App',

  mounted() {
    xray.mainIndex()
  }
}
</script>

<style lang='scss'>
@import "@/assets/scss/style.scss";
</style>
