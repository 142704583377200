import store from '..'

export default {
  getCurrentPatient: (state) => {
    if (localStorage.getItem('current-patient')) {
      store.commit('Patient/setCurrentPatient', JSON.parse(localStorage.getItem('current-patient')))
    }

    return state.currentPatient
  }
}
