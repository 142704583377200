import Cookies from 'js-cookie'
import constant from '@/config/constant'
import auth from '../../services/auth'
import store from '..'

export default {
  loginAction (context, payload) {
    let accountPermissions = []

    auth.getAccountAndPermissions(payload).then((response) => {
      store.dispatch('Account/setCurrentAccount', response.data.res_data.account_permissions[0].account)
      accountPermissions = response.data.res_data.account_permissions
      window.localStorage.setItem('app-account-permissions', JSON.stringify(accountPermissions))
      context.commit('authSetPermissions', response.data.res_data.permissions)
    })

    auth.me(payload, accountPermissions).then((response) => {
      context.commit('authSetUser', response.data)
      window.localStorage.setItem('theme-color', response.data.current_theme)
      Cookies.set('auth-user', response.data.name, { expires: 0.5 })
    })
  },

  setAuthUser (context, user) {
    context.commit('authSetUser', user)
  },

  logoutAction (context, payloadWithSite = false) {
    context.commit('authLogout')

    // Payload para verificar se é um login ou logout
    if (!payloadWithSite) {
      window.location.href = constant.vitaleSiteUrl
    }
  }
}
