export default {
  START_PATIENT_CARE(state, payload) {
    state.patientCare = payload
  },

  FINISH_PATIENT_CARE(state) {
    state.patientCare = null
  },

  UPDATE_PATIENT_CARE(state, payload) {
    state.patientCare = payload
  }
}
