<template>
  <!-- Footer -->
  <footer class="iq-footer shadow-sm mt-auto d-print-none">
    <div class="container-fluid px-4">
      <div class="row">
        <div class="col-4 col-lg-3 footer-col">
          <slot name="left" />
        </div>
        <div class="col-4 col-lg-6 my-2 my-lg-0 footer-col">
          <slot name="center" />
        </div>
        <div class="col-4 col-lg-3 footer-col">
          <slot name="right" />
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer END -->
</template>
<script>
export default {
  name: 'FooterStyle1',

  mounted () {
    setTimeout(() => {
      document.getElementById('terminal').focus()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.footer-col {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border: 0 !important;
  }

  p {
    opacity: 0.7;
  }

  p {
    font-size: 12px;
    cursor: pointer;
    line-height: 1.3;

    &:hover {
      opacity: 1 !important;
    }
  }

  input {
    color: #fff;
    background: transparent;
    border: 0 !important;
  }

  @media only screen and (min-width: 992px) {
    border-left: 1px solid transparent;
  }
}
</style>
