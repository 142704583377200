<template>
  <div class="iq-sidebar sidebar-main" no-header="true">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
        <img :src="logo" alt="logo" class="img-fluid" style="opacity: 0" />
        <span />
      </router-link>
      <div v-if="toggleButton" class="iq-menu-bt-sidebar">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle">
              <i class="ri-more-fill" />
            </div>
            <div class="hover-circle">
              <i class="ri-more-2-fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <perfect-scrollbar>
          <List :items="items" :open="true" class="py-5" />
        </perfect-scrollbar>
      </nav>
      <!--      <div class="p-3"></div>-->
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import List from '../menus/ListStyle1'

export default {
  name: 'SideBarStyle1',

  components: {
    List,
    PerfectScrollbar
  },

  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.home' }) },
    items: { type: Array, default: () => [] },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },

  data() {
    return {}
  },

  mounted() {
    const element = document.querySelectorAll('.iq-sidebar-menu li a')
    Array.from(element, (elem) => {
      elem.addEventListener('click', () => {
        const prevMenu = window
          .$('.iq-sidebar-menu li')
          .has('.iq-submenu')
          .find('ul.show')
          .attr('id')
        const prevMenuElem = document.getElementById(prevMenu)
        if (prevMenuElem !== null) {
          Array.from(
            prevMenuElem.closest('li').getElementsByClassName('not-collapsed'),
            () => {
              if (elem.closest('ul').getAttribute('id') !== prevMenu) {
                this.$root.$emit('bv::toggle::collapse', prevMenu)
                prevMenuElem.closest('li').classList.remove('active')
              }
              if (elem.getAttribute('aria-controls') === prevMenu) {
                this.$root.$emit('bv::toggle::collapse', prevMenu)
              }
            }
          )
        }
        window.$(elem).closest('li').has('.iq-submenu').addClass('active')
      })
    })
  },

  methods: {
    miniSidebar() {
      this.$emit('toggle')
    }
  }
}
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style>
.ps {
  max-height: calc(100vh - 20px);
}
</style>
