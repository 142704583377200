export default {
  start({ commit }, payload) {
    commit('START_PATIENT_CARE', payload)
  },

  finish({ commit }) {
    commit('FINISH_PATIENT_CARE')
  },

  update({ commit }, payload) {
    commit('UPDATE_PATIENT_CARE', payload)
  }
}
