import Vue from 'vue'
import moment from 'moment'
import 'moment/dist/locale/pt-br'

Vue.filter('reverse', (value) =>
  // slice to make a copy of array, then reverse the copy
  value.slice().reverse()
)

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).locale('pt-br').fromNow()
  }
})

Vue.filter('formatDateToBr', (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateToHours', (date) => {
  if (date) {
    return moment(date).format('HH:mm')
  }
})

Vue.filter('formatDateAndTimeToBr', (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }
})
