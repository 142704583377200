<template>
  <li>
    <div
      :class="{bold: isFolder}"
      @click="toggle"
      @dblclick="makeFolder"
    >
      <span v-if="isFolder">
        <template v-if="isOpen">
          <template v-if="hasGroupOpenSlot">
            <slot name="groupClosed" />
          </template>
          <template v-else>
            <i class="fas fa-minus-square" />
          </template>
        </template>
        <template v-else>
          <template v-if="hasGroupCloseSlot">
            <slot name="groupOpened" />
          </template>
          <template v-else>
            <i class="fas fa-plus-square" />
          </template>
        </template>
      </span>
      <span v-else>
        <template v-if="hasItemSlot">
          <slot name="item" />
        </template>
        <template v-else>
          <i class="fas fa-caret-square-right" />
        </template>
      </span> {{ item.name }}
    </div>
    <ul
      v-show="isOpen"
      v-if="isFolder"
      class="tree"
    >
      <template v-if="item.children">
        <TreeView
          v-for="(child, index) in item.children"
          :key="index"
          class=""
          :item="child"
        >
          <template #groupOpened>
            <template v-if="hasGroupOpenSlot">
              <slot name="groupOpened" />
            </template>
            <template v-else>
              <i class="fas fa-plus-square" />
            </template>
          </template>
          <template #groupClosed>
            <template v-if="hasGroupOpenSlot">
              <slot name="groupClosed" />
            </template>
            <template v-else>
              <i class="fas fa-minus-square" />
            </template>
          </template>
          <template #item>
            <template v-if="hasItemSlot">
              <slot name="item" />
            </template>
            <template v-else>
              <i class="fas fa-caret-square-right" />
            </template>
          </template>
        </TreeView>
      </template>
      <!--<li class="add" @click="$emit('add-item', item)">+</li>-->
    </ul>
  </li>
</template>

<script>
import TreeView from './TreeView'

export default {
  name: 'TreeView',
  comments: {
    TreeView
  },
  props: ['item', 'isOpened'],
  data () {
    return {
      isOpen: !!this.isOpened
    }
  },
  computed: {
    isFolder () {
      return this.item.children
          && this.item.children.length
    },
    hasGroupOpenSlot () {
      return !!this.$slots.groupOpened
    },
    hasGroupCloseSlot () {
      return !!this.$slots.groupClosed
    },
    hasItemSlot () {
      return !!this.$slots.item
    }
  },
  methods: {
    toggle () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
    },
    makeFolder () {
      if (!this.isFolder) {
        this.$emit('make-folder', this.item)
        this.isOpen = true
      }
    }
  }
}
</script>
