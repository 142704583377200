export default {
  START_SERVICE (state, service) {
    state.currentService = service
  },

  UPDATE_SERVICE (state, service) {
    state.currentService = service
  },

  FINISH_SERVICE (state) {
    state.currentService = null
  }
}
