import store from '../store'

export default {
  group: null,

  showMenu (_groups) {
    const currentAccountId = store.getters['Account/accountGetAccount'].id
    const currentUser = store.getters['Auth/getAuthUserLogged']

    if (!this.group) {
      const accounts = store.getters['Account/accountGetMyAccounts'].filter(
        (account) => account.account.id === currentAccountId
      )

      if (accounts.length > 0) {
        this.group = accounts[0].account.group
      }
    }

    if (this.group && _groups) {
      if (currentUser.administrator) {
        if (_groups.includes('owner')) {
          return true
        }
      }
      return _groups.includes(this.group.tag)
    }

    return false
  }
}
