import MedicalAppointmentService from '@/services/MedicalAppointment'

export default {
  async startService({commit}, service) {
    await commit('START_SERVICE', service)
  },

  async getServices({commit}, patientId) {
    await MedicalAppointmentService.inAttendance().then((response) => {
      let res = response.data.res_data

      let svc = res.find((service) => service.patient_id === patientId)

      if (svc != null) {
        commit('START_SERVICE', svc)
        return
      }

      commit('FINISH_SERVICE')
    })
  },

  async finishService({commit}) {
    commit('FINISH_SERVICE')
  }
}
