import Cookies from 'js-cookie'

export default {
  authSetUser(state, user) {
    state.user = user
    localStorage.setItem('vitale-user', JSON.stringify(user))
  },

  authSetPermissions(state, permissions) {
    state.permissions = permissions
    localStorage.setItem('vitale-permissions', JSON.stringify(permissions))
  },

  authLogout(state) {
    Cookies.remove('auth-user')
    localStorage.removeItem('app-account-permissions')
    localStorage.removeItem('app-token')
    localStorage.removeItem('current-patient')
    localStorage.removeItem('calendar-item')
    localStorage.removeItem('current-specialist')
    localStorage.removeItem('theme-color')
    localStorage.removeItem('vitale-account')
    localStorage.removeItem('vitale-permissions')
    localStorage.removeItem('vitale-subdomain')
    localStorage.removeItem('vitale-user')

    state.user = null
  }
}
