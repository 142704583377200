import Vue from 'vue'

Vue.mixin({
  methods: {
    validateCPF(cpf) {
      let sum
      let rest
      cpf = cpf.replace(/\D/g, '')

      sum = 0

      if (cpf === '00000000000') return false

      for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
      rest = (sum * 10) % 11

      if (rest === 10 || rest === 11) rest = 0
      if (rest !== parseInt(cpf.substring(9, 10))) return false

      sum = 0
      for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
      rest = (sum * 10) % 11

      if (rest === 10 || rest === 11) rest = 0

      return rest === parseInt(cpf.substring(10, 11))
    },

    // Rotina de validação de Números que iniciam com 1 ou 2
    validaCns(cns) {
      if (cns.trim().length != 15) return false

      let soma
      let resto
      let
        dv

      let pis = ''
      let resultado = ''
      pis = cns.substring(0, 11)

      soma = (Number(pis.substring(0, 1)) * 15)
        + (Number(pis.substring(1, 2)) * 14)
        + (Number(pis.substring(2, 3)) * 13)
        + (Number(pis.substring(3, 4)) * 12)
        + (Number(pis.substring(4, 5)) * 11)
        + (Number(pis.substring(5, 6)) * 10)
        + (Number(pis.substring(6, 7)) * 9)
        + (Number(pis.substring(7, 8)) * 8)
        + (Number(pis.substring(8, 9)) * 7)
        + (Number(pis.substring(9, 10)) * 6)
        + (Number(pis.substring(10, 11)) * 5)

      resto = soma % 11
      dv = 11 - resto

      if (dv == 11) dv = 0

      if (dv == 10) {
        soma = (Number(pis.substring(0, 1)) * 15)
          + (Number(pis.substring(1, 2)) * 14)
          + (Number(pis.substring(2, 3)) * 13)
          + (Number(pis.substring(3, 4)) * 12)
          + (Number(pis.substring(4, 5)) * 11)
          + (Number(pis.substring(5, 6)) * 10)
          + (Number(pis.substring(6, 7)) * 9)
          + (Number(pis.substring(7, 8)) * 8)
          + (Number(pis.substring(8, 9)) * 7)
          + (Number(pis.substring(9, 10)) * 6)
          + (Number(pis.substring(10, 11)) * 5) + 2

        resto = soma % 11
        dv = 11 - resto
        resultado = `${pis}001${dv.valueOf()}`
      } else {
        resultado = `${pis}000${dv.valueOf()}`
      }

      return cns == resultado
    },

    // Rotina de validação de Números que iniciam com 7, 8 ou 9
    validaCnsProv(cns) {
      if (cns.trim().length != 15) return false

      let resto
      let
        soma

      soma = (Number(cns.substring(0, 1)) * 15)
        + (Number(cns.substring(1, 2)) * 14)
        + (Number(cns.substring(2, 3)) * 13)
        + (Number(cns.substring(3, 4)) * 12)
        + (Number(cns.substring(4, 5)) * 11)
        + (Number(cns.substring(5, 6)) * 10)
        + (Number(cns.substring(6, 7)) * 9)
        + (Number(cns.substring(7, 8)) * 8)
        + (Number(cns.substring(8, 9)) * 7)
        + (Number(cns.substring(9, 10)) * 6)
        + (Number(cns.substring(10, 11)) * 5)
        + (Number(cns.substring(11, 12)) * 4)
        + (Number(cns.substring(12, 13)) * 3)
        + (Number(cns.substring(13, 14)) * 2)
        + (Number(cns.substring(14, 15)) * 1)

      resto = soma % 11

      return resto == 0
    },

    cnsValido(cns) {
      cns.trim()
      cns = cns.split(' ').join('')
      const numeroInicial = cns.substring(0, 1)

      return numeroInicial == 1 || numeroInicial == 2 ? this.validaCns(cns) : this.validaCnsProv(cns)
    }
  }
})
