import Vue from 'vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

const echo = new Echo({
  broadcaster: 'pusher',
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  auth: {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('app-token')}`
    }
  },
  cluster: 'mt1',
  key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: 6001,
  wssPort: 443,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss']
})

Vue.prototype.$echo = echo
export default Vue
