import store from '..'

export default {
  accountGetAccounts: (state) => state.accounts,

  accountGetAccount: (state) => {
    if (state.account) {
      return state.account
    }

    if (localStorage.getItem('vitale-account')) {
      store.commit('Account/accountSetAccount', JSON.parse(localStorage.getItem('vitale-account')))
    }

    return state.account
  },

  accountGetMyAccounts: () => JSON.parse(localStorage.getItem('app-account-permissions'))
}
