<template>
  <!-- Right Sidebar Panel Start-->
  <div :class="`right-sidebar-mini `+miniClass">
    <div
      class="right-sidebar-toggle"
      :class="toggleClass"
      @click="toggleMini"
    >
      <slot
        v-if="hasIconSlot"
        name="icon"
      />
      <template v-else>
        <slot
          v-if="hasOpenIconSlot"
          name="openIcon"
          class="side-left-icon"
        />
        <i
          v-else
          class="ri-arrow-left-line side-left-icon"
        />
        <slot
          v-if="hasCloseIconSlot"
          name="closeIcon"
          class="side-right-icon"
        />
        <i
          v-else
          class="ri-arrow-right-line side-right-icon"
        />
      </template>
    </div>
    <div class="right-sidebar-panel p-0">
      <slot />
    </div>
  </div>
  <!-- Right Sidebar Panel End-->
</template>
<script>
export default {
  name: 'RightSideBarStyle1',
  props: {
    toggleClass: { type: String, default: '' }
  },
  data () {
    return {
      rightSideBarMini: false,
      miniClass: ''
    }
  },
  computed: {
    hasIconSlot () {
      return !!this.$slots.icon
    },
    hasOpenIconSlot () {
      return !!this.$slots.openIcon
    },
    hasCloseIconSlot () {
      return !!this.$slots.closeIcon
    }
  },
  methods: {
    toggleMini () {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar () {
      if (this.rightSideBarMini) {
        this.miniClass = 'right-sidebar'
      } else {
        this.miniClass = ''
      }
    }
  }
}
</script>
