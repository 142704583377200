import axios from 'axios'
import tenant from '@/config/tenant'

export default {
  getAccountAndPermissions(access_token) {
    const client = axios.create({ baseURL: tenant.buildTenantUrl() })
    return client.post(
      '/auth/me/get-account-and-permissions',
      {},
      { headers: { Authorization: `Bearer ${access_token}` } }
    )
  },

  reset(email) {
    const client = axios.create({ baseURL: tenant.buildTenantUrl() })
    return client.post('/auth/password', { email })
  },

  me(access_token, account_permissions) {
    const client = axios.create({ baseURL: tenant.buildTenantUrl() })
    window.localStorage.setItem('app-token', access_token)
    window.localStorage.setItem(
      'app-account-permissions',
      JSON.stringify(account_permissions)
    )

    return client.post(
      '/auth/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
  }
}
