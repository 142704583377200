export default {
  accountSetAccounts(state, response) {
    state.accounts = response
  },

  accountSetAccount(state, account) {
    state.account = account
    const domains = JSON.parse(localStorage.getItem('auth-domains'))

    const currentDomain = domains.find((domain) => domain.account_id === account.id).domain

    localStorage.setItem('vitale-subdomain', currentDomain)

    localStorage.setItem('vitale-account', JSON.stringify(account))
  }
}
