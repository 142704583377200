import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Função para obter o valor do parâmetro "i18n_redirected" da URL
function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

// Obtém o valor do parâmetro "i18n_redirected" da URL
let i18nRedirectedValue =
  getParameterByName('i18n_redirected') ||
  localStorage.getItem('i18n_redirected')

if (getParameterByName('i18n_redirected')) {
  localStorage.setItem('i18n_redirected', i18nRedirectedValue)
}

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
  // Usar o valor do parâmetro ou 'pt' como padrão
  locale: i18nRedirectedValue || 'pt',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
