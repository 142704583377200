import axios from '../'

export default {
  paginate (params = {}) {
    let filters = []
    filters = Object.entries(params).map((v) => `filter[${v[0]}]=${v[1]}`)

    return axios.get(`/admin/accounts?${filters.join('&')}`)
  },

  createAccount (data) {
    return axios.post('/admin/accounts', data)
  },

  getAccountById (id) {
    return axios.get(`/admin/accounts/${id}`)
  },

  updateAccountById (id, accountData) {
    accountData.append('_method', 'PUT')

    return axios.post(`/admin/accounts/${id}`, accountData, {
      headers: {
        account_id: id,
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  deleteAccountById (id) {
    return axios.delete(`/admin/accounts/${id}`)
  },

  followOrUnfollow (id, userId) {
    return axios.post(`/admin/accounts/${id}/user/${userId}/relationship`)
  },

  getLicense (id) {
    return axios.get(`/admin/accounts/${id}/license`, {
      headers: {
        account_id: id
      }
    })
  }


}
