import axios from 'axios'
import store from '@/store'
import tenant from '@/config/tenant'

const client = axios.create({
  baseURL: tenant.buildTenantUrl()
})

const getAccount = () => {
  const account = localStorage.getItem('vitale-account')

  if (account != null) {
    return JSON.parse(account).id
  }

  return null
}

client.interceptors.request.use((config) => {
  const token = localStorage.getItem('app-token')
  const accountPermissions = JSON.parse(
    localStorage.getItem('app-account-permissions')
  )
  const accountId = getAccount()

  if (accountPermissions == null) {
    return config
  }

  config.headers.Authorization = `Bearer ${token}`
  config.headers.account_id = accountId

  return config
})

client.interceptors.response.use(
  (response) =>
    response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('Auth/logoutAction')
      window.location = '/'
    }
    return Promise.reject(error)
  }
)

export default client
