import Vue from 'vue'
import Vuex from 'vuex'
// import Chat from './Chat/index'
import Auth from './Auth'
import Account from './Account'
import MedicalAppointment from './MedicalAppointment'
import Patient from './Patient'
import Notification from './Notification'
import PatientCare from './PatientCare'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Auth,
    Account,
    // Chat,
    MedicalAppointment,
    Patient,
    Notification,
    PatientCare
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug
})
