var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-collapse',{class:_vm.className,attrs:{"id":_vm.idName,"accordion":_vm.accordianName,"visible":_vm.open,"tag":"ul"}},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:[
        item.is_heading
          ? 'sidebar-menu-item'
          : _vm.activeLink(item) && item.children
            ? 'active'
            : _vm.activeLink(item)
              ? 'active'
              : '',
        {
          'd-none':
            _vm.currentAccount.company_type != 'hospital' &&
            ['sidebar.hospital.root', 'sidebar.sus.root'].includes(item.title)
        },
        {
          'd-none':
            _vm.currentAccount.company_type != 'university' &&
            item.title === 'sidebar.university.root'
        }
      ]},[(item.is_heading)?_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu(item.groups)),expression:"showMenu(item.groups)"}],staticClass:"ri-subtract-line"}):_vm._e(),(item.is_heading)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu(item.groups)),expression:"showMenu(item.groups)"}]},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]):_vm._e(),(!item.is_heading)?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu(item.groups)),expression:"showMenu(item.groups)"},{name:"b-modal",rawName:"v-b-modal",value:(item.id_modal),expression:"item.id_modal"},{name:"b-toggle",rawName:"v-b-toggle",value:(item.name),expression:"item.name"}],staticClass:"left-sidebar-link",class:[
          `iq-waves-effect ${
            _vm.activeLink(item) && item.children
              ? 'active'
              : _vm.activeLink(item)
                ? 'active'
                : ''
          }`,
          item.class_name !== null ? item.class_name : ''
        ],attrs:{"to":item.link}},[(item.is_icon_class)?_c('i',{class:item.icon}):void 0,_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]),(item.children)?_c('i',{staticClass:"ri-arrow-right-s-line iq-arrow-right"}):_vm._e(),_c('small',{class:item.append_class,domProps:{"innerHTML":_vm._s(item.append)}})],2):_vm._e(),(item.children)?_c('MenuList',{attrs:{"accordian-name":`sidebar-accordion-${item.class_name}`,"class-name":`iq-submenu ${item.class_name}`,"id-name":item.name,"items":item.children,"open":item.link.name !== '' && _vm.activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && _vm.activeLink(item))}}):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }