import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import store from '@/store'

Vue.use(VueRouter)

const kitProceduresChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ProceduresIndex' },
    component: () => import('@/views/Customizations/KitProcedures/Index')
  },
  {
    path: '/createOrUpdate/:id?',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ProceduresCreate' },
    component: () => import('@/views/Customizations/KitProcedures/Create')
  }
]

const proceduresChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ProceduresIndex' },
    component: () => import('@/views/Customizations/Procedures/Index')
  }
]

const chatChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ChatIndex' },
    component: () => import('@/views/Apps/Chat/Index')
  }
]

const waitingRoomChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'WaitingRoomIndex' },
    component: () => import('@/views/WaitingRoom/Index')
  },
  {
    path: 'edit',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'WaitingRoomIndex' },
    component: () => import('@/views/WaitingRoom/Index')
  }
]

const waitingRoomPreviewRoutes = (prop, mode) => [
  {
    path: 'preview',
    name: `${prop}.preview`,
    meta: { dark: mode, auth: true, name: 'WaitingRoomPreview' },
    component: () => import('@/views/WaitingRoom/Preview')
  }
]

const medicalPrescriptionTemplateChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'MedicalPrescriptionTemplateIndex' },
    component: () => import('@/views/MedicalPrescriptionTemplate/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'MedicalPrescriptionTemplateCreate' },
    component: () =>
      import('@/views/MedicalPrescriptionTemplate/CreateOrUpdate')
  },
  {
    path: 'edit/:id',
    name: `${prop}.edit`,
    meta: { dark: mode, auth: true, name: 'MedicalPrescriptionTemplateUpdate' },
    component: () =>
      import('@/views/MedicalPrescriptionTemplate/CreateOrUpdate')
  }
]

const accountChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'AccountIndex' },
    component: () => import('@/views/Accounts/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'AccountCreate' },
    component: () => import('@/views/Accounts/Create')
  },
  {
    path: 'preview/:id',
    name: `${prop}.preview`,
    meta: { dark: mode, auth: true, name: 'AccountPreview' },
    component: () => import('@/views/Accounts/Preview')
  }
]

// const totemIndexChildRoute = (prop, mode) => [
//   {
//     path: ':id',
//     name: `${prop}.index`,
//     meta: { dark: mode, auth: true, name: 'TotemIndex' },
//     component: () => import('@/views/Totem/Index')
//   }
// ]

const error404IndexChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'Error0Index' },
    component: () => import('@/views/Pages/Error404')
  }
]

// const hotSiteIndexChildRoute = (prop, mode) => [
//   {
//     path: '/',
//     name: `${prop}.index`,
//     meta: { dark: mode, auth: true, name: 'HotSite' },
//     component: () => import('@/views/HealthUnit/PreviewTwo')
//   }
// ]

const financeChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'FinanceIndex' },
    component: () => import('@/views/Finance/Index')
  },
  // {
  //   path: 'cash-flow',
  //   name: `${prop}.cash_flow.index`,
  //   meta: {dark: mode, auth: true, name: 'FinanceCashFlowIndex'},
  //   component: () => import('@/views/Finance/CashFlow/Index')
  // },
  {
    path: 'bank-accounts',
    name: `${prop}.bank_accounts.index`,
    meta: { dark: mode, auth: true, name: 'FinanceBankAccountsIndex' },
    component: () => import('@/views/Finance/BankAccounts/Index')
  },
  // {
  //   path: 'bills-to-pay',
  //   name: `${prop}.bills_to_pay.index`,
  //   meta: {dark: mode, auth: true, name: 'FinanceBillsToPayIndex'},
  //   component: () => import('@/views/Finance/BillsToPay/Index')
  // },
  // {
  //   path: 'incoming-payments',
  //   name: `${prop}.incoming_payments.index`,
  //   meta: {dark: mode, auth: true, name: 'FinancialIncomingPaymentsIndex'},
  //   component: () => import('@/views/Finance/IncomingPayments/Index')
  // },
  {
    path: 'finance-categories',
    name: `${prop}.categories.index`,
    meta: { dark: mode, auth: true, name: 'FinancialCategoriesIndex' },
    component: () => import('@/views/Finance/Categories/Index')
  }
]

const healthUnitChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: false, name: 'HealthUnitIndex' },
    component: () => import('@/views/HealthUnit/Index')
  },
  {
    path: ':slug',
    name: `${prop}.preview`,
    meta: { dark: mode, auth: false, name: 'HealthUnitPreview' },
    component: () => import('@/views/HealthUnit/Preview')
  }
]

const cardChildRoute = (prop, mode) => [
  {
    path: ':id',
    name: `${prop}.preview`,
    meta: { dark: mode, auth: false, name: 'CardPreview' },
    component: () => import('@/views/Mastercard/Preview')
  }
]

const productsChildRoute = (prop, mode) => [
  {
    path: '/',
    name: 'products.index',
    meta: { dark: mode, auth: true, name: 'ProductIndex' },
    component: () => import('@/views/Pharmacy/Product/Index')
  },
  {
    path: 'product/show',
    name: 'products.show',
    meta: { dark: mode, auth: true, name: 'ProductShow' },
    component: () => import('@/views/Pharmacy/Product/Show')
  },
  {
    path: 'product/create',
    name: 'products.create',
    meta: { dark: mode, auth: true, name: 'ProductCreate' },
    component: () => import('@/views/Pharmacy/Product/Create')
  }
]

const accommodationsChildRoute = (prop, mode) => [
  {
    path: '',
    name: 'accommodation.index',
    meta: { dark: mode, auth: true, name: 'AccommodationIndex' },
    component: () => import('@/views/Accommodation/Index')
  },
  {
    path: 'accommodation/show',
    name: 'accommodation.show',
    meta: { dark: mode, auth: true, name: 'AccommodationShow' },
    component: () => import('@/views/Accommodation/Show')
  },
  {
    path: 'accommodation/create',
    name: 'accommodation.create',
    meta: { dark: mode, auth: true, name: 'AccommodationCreate' },
    component: () => import('@/views/Accommodation/Create')
  }
]

const healthPlansChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'HealthPlanIndex' },
    component: () => import('@/views/HealthPlans/Index')
  },
  {
    path: 'show/:healhPlanId',
    name: `${prop}.show`,
    meta: { dark: mode, auth: true, name: 'HealthPlanShow' },
    component: () => import('@/views/HealthPlans/Show')
  }
]

const statusExamsCreateChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: prop + '.create',
    meta: { dark: mode, auth: true, name: 'StatusExamsCreate' },
    component: () => import('@/views/Exams/StatusExams/Create')
  }
]

const AdmissionFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: prop + '.create',
    meta: { dark: mode, auth: true, name: 'AdmissionFormCreate' },
    component: () => import('@/views/AdmissionForm/Create')
  }
]

const AdmissionFormTemplateCreateChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: prop + '.create',
    meta: { dark: mode, auth: true, name: 'AdmissionFormTemplateCreate' },
    component: () => import('@/views/AdmissionFormTemplate/Create')
  }
]

const customAnamnesisChildRoute = (prop, mode) => [
  {
    path: ':group/:templateId/:templateName/:patientId/:userId/:scheduleId?',
    name: `${prop}.form`,
    meta: { dark: mode, auth: true, name: 'CustomAnamnesisForm' },
    component: () => import('@/views/CustomAnamnesis/Index')
  }
]

const customAnamnesisTemplateChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'CustomAnamnesisTemplateIndex' },
    component: () => import('@/views/CustomAnamnesisTemplate/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'CustomAnamnesisTemplateCreate' },
    component: () => import('@/views/CustomAnamnesisTemplate/CreateOrUpdate')
  },
  {
    path: 'edit/:id',
    name: `${prop}.edit`,
    meta: { dark: mode, auth: true, name: 'CustomAnamnesisTemplateUpdate' },
    component: () => import('@/views/CustomAnamnesisTemplate/CreateOrUpdate')
  }
]

const termOfConsentChildRoute = (prop, mode) => [
  {
    path: ':templateId/:templateName/:patientId/:userId/:scheduleId?',
    name: `${prop}.form`,
    meta: { dark: mode, auth: true, name: 'TermOfConsentForm' },
    component: () => import('@/views/TermOfConsent/Index')
  }
]

const termOfConsentTemplateChildRoutes = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'TermsOfConsentTemplatesIndex' },
    component: () => import('@/views/TermsOfConsentTemplate/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'TermsOfConsentTemplatesCreate' },
    component: () => import('@/views/TermsOfConsentTemplate/CreateOrShow')
  },
  {
    path: 'show/:term_id',
    name: `${prop}.show`,
    meta: { dark: mode, auth: true, name: 'TermsOfConsentTemplatesShow' },
    component: () => import('@/views/TermsOfConsentTemplate/CreateOrShow')
  }
]

const medicalAppointmentTemplateChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'MedicalAppointmentTemplateIndex' },
    component: () => import('@/views/MedicalAppointmentTemplate/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'MedicalAppointmentTemplateCreate' },
    component: () => import('@/views/MedicalAppointmentTemplate/CreateOrUpdate')
  },
  {
    path: 'edit/:id',
    name: `${prop}.edit`,
    meta: { dark: mode, auth: true, name: 'MedicalAppointmentTemplateShow' },
    component: () => import('@/views/MedicalAppointmentTemplate/CreateOrUpdate')
  }
]

const medicalCertificateTemplateChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'MedicalCertficateTemplateIndex' },
    component: () => import('@/views/MedicalCertificateTemplate/Index')
  },
  {
    path: 'edit/:certificate_id',
    name: `${prop}.show`,
    meta: { dark: mode, auth: true, name: 'MedicalCertficateTemplateShow' },
    component: () => import('@/views/MedicalCertificateTemplate/Edit')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'MedicalCertficateTemplateCreate' },
    component: () => import('@/views/MedicalCertificateTemplate/Create')
  }
]

// const patientChildRoute = (prop, mode) => [
//   {
//     path: '/',
//     name: `${prop}.index`,
//     meta: { dark: mode, auth: true, name: 'PatientIndex' },
//     component: () => import('@/views/Patients/Index')
//   },
//   {
//     path: 'show/:patient_id',
//     name: `${prop}.profile`,
//     meta: { dark: mode, auth: true, name: 'PatientProfile' },
//     component: () => import('@/views/Patients/Profile')
//   },
//   {
//     path: 'create',
//     name: `${prop}.create`,
//     meta: { dark: mode, auth: true, name: 'PatientCreate' },
//     component: () => import('@/views/Patients/Create')
//   }
// ]

const authChildRoutes = (prop, mode = false) => [
  {
    path: 'login',
    name: `${prop}.sign-in`,
    meta: { dark: mode, auth: false },
    component: () => import('@/views/AuthPages/Default/SignIn')
  },
  {
    path: 'sign-up1',
    name: `${prop}.sign-up1`,
    meta: { dark: mode, auth: false },
    component: () => import('@/views/AuthPages/Default/SignUp')
  },
  {
    path: 'password',
    name: `${prop}.password`,
    meta: { dark: mode, auth: false },
    component: () => import('@/views/AuthPages/Default/RecoverPassword')
  }
  // {
  //   path: 'lock-screen1',
  //   name: `${prop}.lock-screen1`,
  //   meta: { dark: mode, auth: false },
  //   component: () => import('@/views/AuthPages/Default/LockScreen1')
  // },
  // {
  //   path: 'confirm-mail1',
  //   name: `${prop}.confirm-mail1`,
  //   meta: { dark: mode, auth: false },
  //   component: ConfirmMail1
  // }
]

const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: `${prop}.error`,
    meta: { dark: mode, auth: true },
    component: () => import('@/views/Pages/ErrorPage')
  },
  {
    path: 'coming-soon',
    name: `${prop}.coming-soon`,
    meta: { dark: mode, auth: true },
    component: () => import('@/views/Pages/ComingSoon')
  },
  {
    path: 'maintenance',
    name: `${prop}.maintenance`,
    meta: { dark: mode, auth: true },
    component: () => import('@/views/Pages/Maintenance')
  }
]

const adminUserChildRoute = (prop, mode = false) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'UserIndex' },
    component: () => import('@/views/Users/Index')
  },
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'UserCreate' },
    component: () => import('@/views/Users/Create')
  },
  {
    path: 'preview/:user_code',
    name: `${prop}.preview`,
    meta: { dark: mode, auth: true, name: 'UserPreview' },
    component: () => import('@/views/Users/Profile')
  }
]

// REVER NOME
const atenderChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'AtenderCreate' },
    component: () =>
      import('@/views/Almoxarifadoefarmacia/AtenderRequisicao/Create')
  }
]

const categoryIndexChildRouteChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'Category' },
    component: () => import('@/views/Pharmacy/Category/Index')
  }
]

const sectorIndexChildRouteChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'Sector' },
    component: () => import('@/views/Pharmacy/Sector/Index')
  }
]

const supplierIndexChildRouteChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: () => import('@/views/Pharmacy/Supplier/Index')
  }
]

const observacaoChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ObservacaoCreate' },
    component: () => import('@/views/Almoxarifadoefarmacia/Observacao/Create')
  }
]

const saidaChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'SaidaCreate' },
    component: () =>
      import('@/views/Almoxarifadoefarmacia/SaidasDiversas/Create')
  }
]

const medicamentoChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'MedicamentoCreate' },
    component: () => import('@/views/Almoxarifadoefarmacia/Medicamento/Create')
  }
]

const susTerritorialRegisterChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'TerritorialRegisterIndex' },
    component: () => import('@/views/Hospital/SUS/TerritorialRegister/Index')
  }
]
//
const professionalChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ProfessionalCreate' },
    component: () => import('@/views/Hospital/Professional/Create')
  }
]

const susTerritorialVisitChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'TerritorialVisitCreate' },
    component: () => import('@/views/Hospital/SUS/TerritorialVisit/Create')
  }
]

const healthInsuranceFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'HealthInsuranceFormCreate' },
    component: () =>
      import('@/views/Hospital/Convenio/HealthInsuranceForm/Create')
  }
]

const applicationGuideFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ApplicationGuideFormCreate' },
    component: () =>
      import('@/views/Hospital/Convenio/ApplicationGuideForm/Create')
  }
]

const honoraryGuideFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'HonoraryGuideFormCreate' },
    component: () =>
      import('@/views/Hospital/Convenio/HonoraryGuideForm/Create')
  }
]

const tissBillingFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'TissBillingFormCreate' },
    component: () => import('@/views/Hospital/Convenio/TissBillingForm/Create')
  }
]
const spSadtGuideFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'SpSadtGuideFormCreate' },
    component: () => import('@/views/Hospital/Convenio/SpSadtGuideForm/Create')
  }
]

const ophthalmologyFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'OphthalmologyFormCreate' },
    component: () => import('@/views/Hospital/Ophthalmology/Create')
  },
  {
    path: 'prescription/create',
    name: 'ophthalmology_prescription.create',
    meta: { dark: mode, auth: true, name: 'OphthalmologyPrescriptionCreate' },
    component: () => import('@/views/Hospital/OphthalmologyPrescription/Create')
  }
]

const modulesChildRoute = (prop, mode) => [
  {
    path: '',
    name: 'modules.index',
    meta: {
      dark: mode,
      auth: true,
      name: 'ModulesIndex'
    },
    component: () => import('@/views/Modules/Index')
  }
]

// const agreementProfileFormChildRoute = (prop, mode) => [
//   {
//     path: 'create',
//     name: `${prop}.create`,
//     meta: { dark: mode, auth: true, name: 'AgreementProfileFormCreate' },
//     component: () => import('@/views/Patients/components/Hospital/Convenio/AgreementProfileForm/Create')
//   }
// ]

const planFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'PlanFormCreate' },
    component: () => import('@/views/Hospital/Convenio/PlanForm/Create')
  }
]

const individualDentalFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'IndividualDentalFormCreate' },
    component: () => import('@/views/Hospital/IndividualDentalCare/Create')
  }
]

const susIndividualRegistrationChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'IndividualRegistrationIndex' },
    component: () => import('@/views/Hospital/SUS/IndividualRegistration/Index')
  }
]

const susFoodConsumptionMarkersChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'FoodConsumptionMarkersIndex' },
    component: () => import('@/views/Hospital/SUS/FoodConsumptionMarkers/Index')
  }
]

//
const xmlSusFormChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'SusXmlIndex' },
    component: () => import('@/views/Hospital/XML/Index')
  }
]

// SUS
const individualServiceChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'IndividualServiceIndex' },
    component: () => import('@/views/Hospital/SUS/IndividualService/Index')
  }
]

const susHomeCareChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'HomeCareIndex' },
    component: () => import('@/views/Hospital/SUS/HomeCare/Index')
  }
]

const susCollectiveActivityChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'CollectiveActivityIndex' },
    component: () => import('@/views/Hospital/SUS/CollectiveActivity/Index')
  }
]

const susProcedureChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ProcedureIndex' },
    component: () => import('@/views/Hospital/SUS/Procedure/Index')
  }
]

const stockChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'StockIndex' },
    component: () => import('@/views/Stock/Index')
  },
  {
    path: 'list-exits',
    name: 'list_exits.index',
    meta: { dark: mode, auth: true, name: 'ListExitsIndex' },
    component: () => import('@/views/Stock/components/ListExits/ListExits')
  }
]

const susSupplementarySheetNeurologicalSyndromeChildRoute = (prop, mode) => [
  {
    path: 'index',
    name: `${prop}.index`,
    meta: {
      dark: mode,
      auth: true,
      name: 'SupplementarySheetNeurologicalSyndromeIndex'
    },
    component: () =>
      import(
        '@/views/Hospital/SUS/SupplementarySheetNeurologicalSyndrome/Index'
      )
  }
]

const requestFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'RequestFormCreate' },
    component: () => import('@/views/Hospital/RequestForm/Create')
  }
]

const batchSupplierFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'BatchSupplierFormCreate' },
    component: () => import('@/views/Hospital/BatchSupplierForm/Create')
  }
]
const batchServiceFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'BatchServiceFormCreateCreate' },
    component: () => import('@/views/Hospital/BatchServiceForm/Create')
  }
]

const externalSchedulingFormChildRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ExternalSchedulingCreate' },
    component: () => import('@/views/Hospital/ExternalSchedulingForm/Create')
  }
]

const classesChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ClassesIndex' },
    component: () => import('@/views/Turmas/Index')
  },
  {
    path: '/create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'ClassesCreate' },
    component: () => import('@/views/Turmas/Create')
  },
  {
    path: '/edit/:id',
    name: `${prop}.show`,
    meta: { dark: mode, auth: true, name: 'ClassesShow' },
    component: () => import('@/views/Turmas/Show')
  }
]

const dentalProceduresChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: false, name: 'DentalProceduresIndex' },
    component: () => import('@/views/DentalProcedures/Index')
  }
]

const reportsChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ReportsIndex' },
    component: () => import('@/views/Reports/Index')
  }
]

const profilesChildRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ProfilesIndex' },
    component: () => import('@/views/Profiles/Index')
  }
]

const signatureChildRoute = (prop, mode) => [
  {
    path: '',
    name: 'signature.index',
    meta: { dark: mode, auth: true, name: 'SignatureIndex' },
    component: () => import('@/views/Signature/Index')
  }
]

const exitLogsChildRoute = (prop, mode) => [
  {
    path: ':logs',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'ExitLogsIndex' },
    component: () => import('@/views/Stock/components/ExitModal/ExitLogs')
  }
]

const adminMedicalGuineRoute = (prop, mode) => [
  {
    path: 'create',
    name: `${prop}.create`,
    meta: { dark: mode, auth: true, name: 'AdminMedicalGuineCreate' },
    component: () => import('@/views/Admin/Exams/RequestExams/Create')
  },
  {
    path: ':requestExamTemplateId',
    name: `${prop}.update`,
    meta: { dark: mode, auth: true, name: 'AdminMedicalGuineUpdate' },
    component: () => import('@/views/Admin/Exams/RequestExams/Create')
  }
]

const accountEquipmentRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'AccountEquipmentCreate' },
    component: () => import('@/views/Equipments/index')
  }
]

const whatsappMessageRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'WhatsappMessageIndex' },
    component: () => import('@/views/Whatsapp/index')
  }
]

const displayRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'DisplayIndex' },
    component: () => import('@/views/Display/Index')
  }
]

const environmentRoute = (prop, mode) => [
  {
    path: '/',
    name: `${prop}.index`,
    meta: { dark: mode, auth: true, name: 'EnvironmentIndex' },
    component: () => import('@/views/Environment/Index')
  }
]

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/layouts/AuthLayouts/AuthLayout'),
    meta: { auth: false },
    children: authChildRoutes('auth')
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'dashboard.index',
        meta: { auth: true, name: 'Home' },
        component: () => import('@/views/Dashboards/VitaleDashboards')
      },
      {
        path: 'dashboard',
        redirect: '/'
      }
    ]
  },
  {
    path: '/schedule',
    name: 'schedule',
    meta: { auth: true, name: 'Schedule' },
    component: () => import('@/layouts/VitaleLayout'),
    children: [
      {
        path: '',
        name: 'schedule.index',
        meta: { auth: true, name: 'ScheduleIndex' },
        component: () => import('@/views/Apps/Calendar/Calendar')
      }
    ]
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: financeChildRoutes('finance')
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('@/layouts/BlankLayout'),
    meta: { auth: true },
    children: pagesChildRoutes('default')
  },
  {
    path: '/accounts',
    name: 'account',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: accountChildRoute('account')
  },
  {
    path: '/unit',
    name: 'unit',
    component: () => import('@/layouts/HealthUnitLayout1'),
    meta: { auth: false },
    children: healthUnitChildRoutes('unit')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: termOfConsentTemplateChildRoutes('terms')
  },
  {
    path: '/patients',
    name: 'patients',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: [
      {
        path: ':patient_id/:page?/:item_id?',
        name: 'patients.profile',
        meta: { auth: true, name: 'PatientProfile' },
        component: () => import('@/views/Patients/Profile')
      },
      {
        path: 'create',
        name: 'patients.create',
        meta: { auth: true, name: 'PatientCreate' },
        component: () => import('@/views/Patients/Create')
      }
    ]
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('@/layouts/LifeSaverLayout1'),
    meta: { auth: false },
    children: cardChildRoute('card')
  },
  {
    path: '/health-plans',
    name: 'health-plans',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: healthPlansChildRoute('health_plans')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: productsChildRoute('products')
  },
  {
    path: '/accommodation',
    name: 'accommodation',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: accommodationsChildRoute('accommodations')
  },
  {
    path: '/status-exams',
    name: 'status-exams',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: statusExamsCreateChildRoute('status_exams')
  },
  {
    path: '/admission-form',
    name: 'admission-form',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: AdmissionFormChildRoute('admission_form')
  },
  {
    path: '/admission-form-template',
    name: 'admission-form-template',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: AdmissionFormTemplateCreateChildRoute('admission_form_template')
  },
  {
    path: '/ophthalmology',
    name: 'ophthalmology',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: ophthalmologyFormChildRoute('ophthalmology')
  },
  {
    path: '/medical/certificate-templates',
    name: 'medical-certificate-templates',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: medicalCertificateTemplateChildRoute(
      'medical_certificates_template'
    )
  },
  {
    path: '/medical/prescription-templates',
    name: 'medical-prescription-templates',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: medicalPrescriptionTemplateChildRoute(
      'medical_prescription_template'
    )
  },
  {
    path: '/medical-appointment-templates',
    name: 'medical-appointment-templates',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: medicalAppointmentTemplateChildRoute(
      'medical_appointment_template'
    )
  },
  {
    path: '/custom-anamnesis',
    name: 'custom-anamnesis',
    component: () => import('@/layouts/CustomAnamnesisOrTermOfConsentLayout'),
    meta: { auth: true },
    children: customAnamnesisChildRoute('custom_anamnesis')
  },
  {
    path: '/term-of-consent',
    name: 'term-of-consent',
    component: () => import('@/layouts/CustomAnamnesisOrTermOfConsentLayout'),
    meta: { auth: true },
    children: termOfConsentChildRoute('term_of_consent')
  },
  {
    path: '/custom-anamnesis-templates',
    name: 'custom-anamnesis-templates',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: customAnamnesisTemplateChildRoute('custom_anamnesis_template')
  },
  {
    path: '/classes',
    name: 'classes',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: classesChildRoute('classes')
  },
  {
    path: '/admin/users',
    name: 'admin/users',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: adminUserChildRoute('admin.users')
  },
  {
    path: '/kit-procedures',
    name: 'kit-procedures',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: kitProceduresChildRoutes('kit-procedures')
  },
  {
    path: '/procedures',
    name: 'procedures',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: proceduresChildRoutes('procedures')
  },
  {
    path: '/modules',
    name: 'modules',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: modulesChildRoute('modules')
  },
  {
    path: '/customization',
    name: 'customization',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: [
      // Personalizar > Agenda de doutores
      {
        path: 'schedule/doctors',
        name: 'customization.schedule_for_doctors.index',
        meta: { auth: true, name: 'CustomizationScheduleForDoctors' },
        component: () =>
          import('@/views/Customizations/Schedule/ScheduleForDoctors')
      },
      // Personalizar > Agenda de equipamentos
      {
        path: 'schedule/equipments',
        name: 'customization.schedule_for_equipments.index',
        meta: { auth: true, name: 'CustomizationScheduleForEquipments' },
        component: () =>
          import('@/views/Customizations/Schedule/ScheduleForEquipments')
      },
      // Personalizar > Temas
      {
        path: 'layout',
        name: 'customization.layout.index',
        meta: { auth: true, name: 'CustomizationLayout' },
        component: () => import('@/views/Customizations/Layout/Index')
      },
      // Personalizar > Totem
      {
        path: 'totem',
        name: 'customization.totem.index',
        meta: { auth: true, name: 'CustomizationTotem' },
        component: () => import('@/views/Customizations/Totem/Index')
      }
    ]
  },
  {
    path: '/waiting-room',
    name: 'waiting-room',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: waitingRoomChildRoutes('waiting_room')
  },
  {
    path: '/waiting-room',
    name: 'waiting-room',
    component: () => import('@/layouts/WaitingRoomLayout'),
    children: waitingRoomPreviewRoutes('waiting_room')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: chatChildRoutes('chat')
  },
  {
    path: '/professional',
    name: 'professional',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: professionalChildRoute('professional')
  },
  {
    path: '/sus/individual-service',
    name: '/sus/individual-service',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: individualServiceChildRoute('sus.individual_service')
  },
  {
    path: '/stock',
    name: '/stock',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: stockChildRoute('stock')
  },
  {
    path: '/sus/procedure',
    name: '/sus/procedure',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susProcedureChildRoute('sus.procedure')
  },
  {
    path: '/sus/collective-activity',
    name: '/sus/collective-activity',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susCollectiveActivityChildRoute('sus.collective_activity')
  },
  {
    path: '/sus/home-care',
    name: '/sus/home-care',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susHomeCareChildRoute('sus.home_care')
  },
  {
    path: '/sus/supplementary',
    name: '/sus/supplementary',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children:
      susSupplementarySheetNeurologicalSyndromeChildRoute('sus.supplementary')
  },
  {
    path: '/error',
    name: '/error',
    component: () => import('@/layouts/Error404'),
    meta: { auth: true },
    children: error404IndexChildRoute('Error404')
  },
  {
    path: '/totem/:id',
    name: '/totem',
    component: () => import('@/layouts/TotemLayout'),
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'totem.preview',
        meta: { dark: false, auth: true, name: 'TotemIndex' },
        component: () => import('@/views/Totem/Index')
      }
    ]
  },

  // {
  //     path: '/individual-dental',
  //     name: 'individual-dental',
  //     component: () => import('@/layouts/Layout1'),
  //     meta: {auth: true},
  //     children: individualDentalCareChildRoute('home_form')
  // },
  // REVER
  // {
  //   path: '/agreement-configuration',
  //   name: 'agreement-configuration',
  //   component: () => import('@/layouts/Layout1'),
  //   meta: { auth: true },
  //   children: productRegistrationListChildRouteChildRoute(
  //     'product_registration_list'
  //   )
  // },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: supplierIndexChildRouteChildRoute('supplier')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: categoryIndexChildRouteChildRoute('category')
  },
  {
    path: '/sector',
    name: 'sector',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: sectorIndexChildRouteChildRoute('sector')
  },
  {
    path: '/atender',
    name: 'atender',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: atenderChildRoute('atender')
  },
  {
    path: '/observacao',
    name: 'observacao',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: observacaoChildRoute('observacao')
  },
  {
    path: '/saida',
    name: 'saida',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: saidaChildRoute('saida')
  },
  {
    path: '/medicamento',
    name: 'medicamento',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: medicamentoChildRoute('medicamento')
  },
  {
    path: '/saida',
    name: 'saida',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: saidaChildRoute('saida')
  },
  {
    path: 'sus/food-consumption-markers',
    name: 'sus/food-consumption-markers',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susFoodConsumptionMarkersChildRoute(
      'sus.food_consumption_markers'
    )
  },
  {
    path: '/sus/territorial-visit',
    name: '/sus/territorial-visit',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susTerritorialVisitChildRoute('sus.territorial_visit')
  },
  {
    path: '/health-insurance',
    name: 'health-insurance',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: healthInsuranceFormChildRoute('health_insurance')
  },
  {
    path: '/honorary-guide',
    name: 'honorary-guide',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: honoraryGuideFormChildRoute('honorary_guide')
  },
  {
    path: '/tiss-billing',
    name: 'tiss-billing',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: tissBillingFormChildRoute('tiss_billing')
  },
  {
    path: '/plan-form',
    name: 'plan-form',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: planFormChildRoute('plan_form')
  },
  {
    path: '/spsadt-guide',
    name: 'spsadt-guide',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: spSadtGuideFormChildRoute('spsadt_guide')
  },
  // {
  //   path: '/agreement-profile',
  //   name: 'agreement-profile',
  //   component: () => import('@/layouts/Layout1'),
  //   meta: { auth: true },
  //   children: agreementProfileFormChildRoute('agreement_profile')
  // },
  {
    path: '/application-guide',
    name: 'application-guide',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: applicationGuideFormChildRoute('application_guide')
  },
  {
    path: '/sus/individual-registration',
    name: '/sus/individual-registration',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susIndividualRegistrationChildRoute('sus.individual_registration')
  },
  {
    path: '/individual-dental',
    name: 'individual-dental',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: individualDentalFormChildRoute('individual_dental')
  },
  {
    path: '/request',
    name: 'request',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: requestFormChildRoute('request_form')
  },
  {
    path: '/batch-service',
    name: 'batch-service',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: batchServiceFormChildRoute('batch_service')
  },
  {
    path: '/batch-supplier',
    name: 'batch-supplier',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: batchSupplierFormChildRoute('batch_supplier')
  },

  {
    path: '/external-scheduling',
    name: 'external-scheduling',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: externalSchedulingFormChildRoute('external_scheduling')
  },
  {
    path: '/sus/territorial-register',
    name: '/sus/territorial-register',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: susTerritorialRegisterChildRoute('sus.territorial_register')
  },
  {
    path: '/xml-sus',
    name: 'xml-sus',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: xmlSusFormChildRoute('xml-sus')
  },
  {
    path: '/dental-procedures',
    name: 'dental-procedures',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: dentalProceduresChildRoute('dental_procedures')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: reportsChildRoute('reports')
  },
  {
    path: '/profiles/:id',
    name: 'profiles',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: profilesChildRoute('profiles')
  },
  {
    path: '/signature',
    name: 'signature',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: signatureChildRoute('signature')
  },
  {
    path: '/exit-logs',
    name: 'exit-logs',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: exitLogsChildRoute('exit_logs')
  },
  {
    path: '/admin/medical-guide',
    name: 'admin-medical-guide',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: adminMedicalGuineRoute('admin.medical.guide')
  },
  {
    path: '/admin/procedures',
    name: 'admin.procedures',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'admin.procedures.index',
        meta: { auth: true, name: 'AdminProceduresIndex' },
        component: () => import('@/views/Admin/Procedures/Index')
      }
    ]
  },
  {
    path: '/account-equipment',
    name: 'account-equipment',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: accountEquipmentRoute('account.equipment')
  },
  {
    path: '/whatsapp-message',
    name: 'whatsapp-message',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: whatsappMessageRoute('whatsapp.message')
  },
  {
    path: '/display-totem',
    name: 'display-totem',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: displayRoute('display.totem')
  },
  {
    path: '/environment',
    name: 'environment',
    component: () => import('@/layouts/VitaleLayout'),
    meta: { auth: true },
    children: environmentRoute('environment')
  }

  // { path: '*', component: ErrorPage }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  const userLogged = store.getters['Auth/getAuthUserLogged']
  const authCookie = Cookies.get('auth-user')

  if (
    userLogged !== null &&
    to.name === 'auth.sign-in' &&
    authCookie !== undefined
  ) {
    next({ name: 'dashboard.home' })
  } else if (to.meta.auth === true && userLogged === null) {
    next({ name: 'auth.sign-in' })
  } else {
    next()
  }
})

export default router
