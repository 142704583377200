import AdminAccountService from '@/services/admin/account'

export default {
  // Obtem a lista de contas
  async paginateAccountAction (context, params) {
    return AdminAccountService.paginate(params).then((response) => {
      context.commit('accountSetAccounts', response.data)
      return response.data
    })
  },

  async getAccountById (context, id) {
    return AdminAccountService.getAccountById(id).then((response) => {
      context.commit('accountSetAccount', response.data.data)
    })
  },

  async setCurrentAccount (context, account) {
    context.commit('accountSetAccount', account)
  }
}
