import Vue from 'vue'
import Aos from 'aos'

Vue.use(
  Aos.init({
    offset: 120,
    delay: 0,
    easing: 'ease',
    duration: 1000,
    disable: false,
    once: true,
    startEvent: 'DOMContentLoaded',
    throttleDelay: 99,
    debounceDelay: 50,
    disableMutationObserver: false,
    mirror: false
  })
)
