<template>
  <div>
    <b-collapse
      :id="idName"
      :accordion="accordianName"
      :class="className"
      :visible="open"
      tag="ul"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="[
          item.is_heading
            ? 'sidebar-menu-item'
            : activeLink(item) && item.children
              ? 'active'
              : activeLink(item)
                ? 'active'
                : '',
          {
            'd-none':
              currentAccount.company_type != 'hospital' &&
              ['sidebar.hospital.root', 'sidebar.sus.root'].includes(item.title)
          },
          {
            'd-none':
              currentAccount.company_type != 'university' &&
              item.title === 'sidebar.university.root'
          }
        ]"
      >
        <i
          v-if="item.is_heading"
          v-show="showMenu(item.groups)"
          class="ri-subtract-line"
        />
        <span
          v-if="item.is_heading"
          v-show="showMenu(item.groups)"
        >
          {{ $t(item.name) }}
        </span>
        <router-link
          v-if="!item.is_heading"
          v-show="showMenu(item.groups)"
          v-b-modal="item.id_modal"
          v-b-toggle="item.name"
          :class="[
            `iq-waves-effect ${
              activeLink(item) && item.children
                ? 'active'
                : activeLink(item)
                  ? 'active'
                  : ''
            }`,
            item.class_name !== null ? item.class_name : ''
          ]"
          :to="item.link"
          class="left-sidebar-link"
        >
          <i
            v-if="item.is_icon_class"
            :class="item.icon"
          />
          <template v-else />
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.children"
            class="ri-arrow-right-s-line iq-arrow-right"
          />
          <small
            :class="item.append_class"
            v-html="item.append"
          />
        </router-link>
        <MenuList
          v-if="item.children"
          :accordian-name="`sidebar-accordion-${item.class_name}`"
          :class-name="`iq-submenu ${item.class_name}`"
          :id-name="item.name"
          :items="item.children"
          :open="
            item.link.name !== '' && activeLink(item) && item.children
              ? true
              : !!(item.link.name !== '' && activeLink(item))
          "
        />
      </li>
    </b-collapse>
  </div>
</template>

<script>
import { xray } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import MenuAssembler from '@/config/MenuAssembler'
import MenuList from './ListStyle1'

export default {
  name: 'MenuList',
  components: {
    MenuList
  },
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' }
  },
  data () {
    return {
      group: null,
      licenseModal: '$bvModal.show("use-licenses-modal")'
    }
  },

  computed: {
    ...mapGetters({
      currentAccount: 'Account/accountGetAccount',
      currentUser: 'Auth/getAuthUserLogged'
    })
  },

  methods: {
    showMenu (_groups) {
      return MenuAssembler.showMenu(_groups)
    },

    activeLink (item) {
      return xray.getActiveLink(item, this.$route.name)
    }
  }
}
</script>

<style lang="scss">
[theme='dark'] .sys-shut-down {
  &:hover {
    color: #b50066 !important;
  }
}

.white-space-break {
  white-space: normal !important;
}
</style>